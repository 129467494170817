/* 画面の表示領域をフルに使う */
html, body, #root, .App {
  height: 100%;
  width: 100%;
}

html {
  font-size: 12px;
  
  /* 画面全体のバウンスをさせないための回避策 */
  /* ただ、pwaでなくsafariで開いた時に、上下スクロールができず不便 */
  /* position: fixed; */
}

/* iphone で縦横回転時に文字が拡大しないようにする */
body {
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  -webkit-text-size-adjust: 100%;
}

/* html, body { overflow-x: hidden; overflow-y: auto; } */

* {
  box-sizing:border-box;
}

/* ランドスケープ */
/* @media screen and (orientation:landscape) { ランドスケープ（横長）時のスタイル } */
.container {
  /* height: 100%; */
  /* height: calc(100vh - 100px); */
  /* height: 100vh; */
  min-height: 100%;
  display: grid;
  /* 横 iosではfrが正しく計算されていないようだ */
  /* grid-template-columns: 1fr 10%;  */
  grid-template-columns: 90% 10%;
  /* 縦 iosではfrが正しく計算されていないようだ */
  /* grid-template-rows: 20px 1fr 40px; */
  grid-template-rows: 40px calc(100vh - 80px) 40px;
  grid-template-areas:
    "header header"
    "main aside"
    "footer footer";
}

/* ポートレイト（縦長）時のスタイル */ 
@media screen and (orientation:portrait) {
  .container {
    /* 横 iosではfrが正しく計算されていないようだ */
    /* grid-template-columns: 1fr 15%;  */
    grid-template-columns: 84% 16%; 
  }
}

.header {
  grid-area: header;
}
.main {
  grid-area: main;
  overflow: auto;
}
.aside {
  grid-area: aside;
  overflow: auto;
}
.footer {
  grid-area: footer;
}

.main > .swiper-container {
  width: 100%;
  height: 100%;
}

.footer > .swiper-container {
  width: 100%;
  height: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.footer .swiper-slide img {
  opacity: 0.4;
}

.footer .swiper-slide-thumb-active img {
  opacity: 1;
}

/* アイコンボタン 説明付き */
.const-icon-button {
  display: flex;
  flex-direction: column;
}
.const-icon-button .const-icon-label {
  font-size: 8px;
}