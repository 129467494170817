.Header {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap:nowrap;
  justify-content: space-between;
  align-items: center;
}

.Header-constId {
  flex: 0 0 auto;
}

.Header-constName {
  flex: 1 1 auto;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.Header-counter {
  flex: 0 0 auto;
}
