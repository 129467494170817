.InfoBox{
    position: relative;
    display:inline-flex;
    width: 300px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #999;
}

.InfoBox .Title{
    padding: 3px;
    width: 100px;
    display: flex;
    align-items: center; /* 縦方向中央揃え */
    justify-content: center; /* 横方向中央揃え */
    /* border-right: 1px solid white; */
    background-color: #999;

}

.InfoBox .Value{
    padding: 3px;
    max-width: 200px;
    display: inline;
    text-align: center;
}