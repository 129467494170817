/* ランドスケープ */
/* @media screen and (orientation:landscape) { ランドスケープ（横長）時のスタイル } */
.Photo {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* justify-content: space-around; */
}

.Photo-image {
  width: 70%;
  height: 100%;
  padding: 1px;
}

.Photo-info {
  width: 30%;
  height: 100%;
  padding: 1px;
}

/* 縦長、横長画像に対応 */
.Photo-image > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 0%;
  user-select: none;
}

/* クリップボードのコピーアイコンを右箸にする */
.Photo-info-clipboard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.Photo-info-action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

/* 日付項目：他の入力項目と横位置を合わせる */
.Photo-info-day {
  margin-left: 14px;
}

/* ポートレイト（縦長）時のスタイル */ 
@media screen and (orientation:portrait) { 
  .Photo {
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }

  .Photo-image,
  .Photo-info {
    width: 90%;
    height: 45%;
    padding: 1px;
  }
}