.Sidebar {
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  justify-content: center;
  /* justify-content: space-between; */
  /* justify-content: space-around; */
  /* justify-content: space-evenly; */
  align-items: center;

  /* justify-content: centerの場合overflowすると高さが取れない制限の回避 */
  min-height: -webkit-min-content;
  min-height: min-content;
}

/* 各アイコンの間隔をできるだけ空ける */
 .Sidebar > * {
  margin: 4px;
} 
