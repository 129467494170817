.Thumb {
  display: inline-block;
  height: 100%;
} 

/* 縦長、横長画像に対応 */
.Thumb img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 50%;
}
