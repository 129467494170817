.ConstIdDialog{
    height: 800px;
}
.ConstIdDialog .input-search {
    display: block;
}
.ConstIdDialog .input-process,
.ConstIdDialog .input-favorite {
    display: inline-block;
}
.ConstIdDialog table{
    position: relative;
    display: block;
    font-family: Meiryo,
        "Hiragino Kaku Gothic Pro",
        "MS PGothic",
        Verdana,
        sans-serif;
    font-size: 12px;
    color: black;
    table-layout: fixed;
    width: 100%;
    background-color: white;
    border: 0px none #fff;
}

.ConstIdDialog td {
  background-color: white;
  padding: 2px
}

.ConstIdDialog thead {
  border-bottom: 2px solid black
}

.ConstIdDialog .scrollHead,
.ConstIdDialog .scrollBody {
  display: block;
}

.ConstIdDialog .scrollBody {
  height: 360px;
  overflow-y: scroll;
}

.ConstIdDialog td,
.ConstIdDialog th {
  table-layout: fixed;
}

.ConstIdDialog .choise{
    width: 70px;
}

.ConstIdDialog .constId{
    width: 80px;
}

.ConstIdDialog .name{
    width: 350px;
    min-width: 350px;
    max-width: 350px;
}
.ConstIdDialog .name div{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}

.ConstIdDialog .city{
    width: 300px;
    min-width: 300px;
    max-width: 300px;
}

.ConstIdDialog .city div{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}
.ConstIdDialog .term{
    width: 250px;
}
.ConstIdDialog .process{
    width: 80px;
}

/* スマホサイズ */
@media screen and (max-width: 800px) {
    .ConstIdDialog{
        width: 100%;
        height: 100%;
    }
    .ConstIdDialog .input-search,
    .ConstIdDialog .input-process,
    .ConstIdDialog .input-favorite {
        display: inline-block;
        margin-right: 6px;
    }
    .ConstIdDialog .hr {
        display: none;
    }
    .ConstIdDialog table {
        width: 100%;
        height: 100%;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }
    .ConstIdDialog .scrollHead {
        display: none;
    }
    .ConstIdDialog .scrollBody {
      height: 100%;
      min-width: 1200px;
      overflow-y: hidden;
      /* display:table; */
    }
    .ConstIdDialog tr {
        height: 100%;
    }
    .ConstIdDialog td {
        height: 100%;
        padding-top: 6px;
        padding-bottom: 6px;
    }
}